import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Markdown from 'markdown-to-jsx'

const Post = () => {
    const { slug } = useParams();
    const [postContent, setPostcontent] = useState()

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/data/${slug}.md`)
        .then(response => response.text())
        .then(response => setPostcontent(response))
        .catch(err => console.log(err))
      }, [slug])

    if (!postContent) {
        return "Loading..."
    }

    return (
        <Markdown>
            {postContent}
        </Markdown>
    )
}

export default Post