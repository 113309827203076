import { Routes, Route, Link } from 'react-router-dom'
import About from './routes/About'
import Posts from './routes/Posts'
import Post from './routes/Post'


function App() {
  const getRoutes = () => (
    <Routes>
      <Route path="/" element={<Posts/>} />
      <Route path="/about" element={<About />}/>
      <Route path="/posts/:slug" element={<Post />}/>
    </Routes>
  )

  return (
    <>
      <header className="header">
        <Link className="logo" to="/">home</Link>
        <nav>
          <Link to="https://www.github.com/elderferreiras">github</Link>
          <Link to="/about">about</Link>
        </nav>
      </header>
      <div className="container">
        {getRoutes()}
      </div>
    </>
  );
}

export default App;
