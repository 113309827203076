import React from 'react'

const About = () => (
    <>
      <h1 class="title">About
        <br />
        <span class="subtitle"></span>
      </h1>
      <p>Hi, I'm Elder, and I live in Seattle.</p>
      <p>
      I'm a software developer turned engineering manager. I enjoy reading, but I also like writing, especially when I'm mentally stuck. I created this blog to free up space in my brain. If you stumble upon it, keep this secret to yourself.
      </p>
    </>
)

export default About
