import React from 'react';
import { Link } from 'react-router-dom';

const Posts = () => {
    return (
        <>
            <h1 class="title">Blog</h1>
            <ul class="posts">
                <li class="post">
                <Link to="/posts/2024-06-06-resilient-management-lara-hogan/">
                    <date>06.06.2024</date>
                    <span>Review: Resilient Management by Lara Hogan</span>
                </Link>
                </li>
            </ul>
        </>
    )
}

export default Posts 